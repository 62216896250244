<template>
	<div id="home">
		<CommonHeader></CommonHeader>
		<div class="zzbox" v-show="addcontent">
			<div class="zzcont_box">
				<!--                <span id="x">X</span>-->
				<div class="zz_head">新增事物（输入名称）</div>
				<div class="zzcent">
					<input
						type="text"
						v-model="affair_name"
						@keyup.enter="confirmAdd"
					/>
				</div>
				<div class="zzbot">
					<a @click="addcontent = false">取 消</a>
					<a @click="confirmAdd">确 定</a>
				</div>
			</div>
		</div>
		<!--        用户首次使用添加新手引导-->
		<div class="zzbox" v-show="addcontent && userActive == 1">
			<div
				class="zzcont_box"
				data-step="2"
				data-intro="在这里输入、事物或名称或标志或内容"
			>
				<!--                <span id="x">X</span>-->
				<div class="zz_head">新增事物（输入名称）</div>
				<div class="zzcent">
					<input
						type="text"
						v-model="affair_name"
						@keyup.enter="confirmAdd"
					/>
				</div>
				<div class="zzbot">
					<a @click="addcontent = false">取 消</a>
					<a @click="confirmAdd">确 定</a>
				</div>
			</div>
		</div>
		<div class="wrap">
			<!--      内容左边-->
			<div class="main-left" @click="candit">
				<!--                添加事务-->
				<!--                <div class="add-content" data-step="3" data-intro="完成的内容拖入分类区域">1 添加内容</div>-->
				<div
					class="add-goods"
					@click="oneActive"
					data-step="1"
					data-intro="点击添加输入内容即可"
				>
					<i class="iconfont iconziyuan"></i>
					新增事物
				</div>
				<!--                <el-dialog-->
				<!--                        title="新增事物（输入名称）"-->
				<!--                        :visible.sync="addcontent"-->
				<!--                        width="345px"-->
				<!--                        center-->
				<!--                >-->
				<!--                    <el-input v-model="affair_name" autocomplete="off" @keyup.enter.native="confirmAdd"></el-input>-->
				<!--                    <span slot="footer" class="dialog-footer">-->
				<!--                        <el-button @click="addcontent = false">取 消</el-button>-->
				<!--                        <el-button type="primary" @click="confirmAdd"-->
				<!--                                   style="background: #70CCFF;border: 1px solid #70CCFF;">确 定-->
				<!--                        </el-button>-->
				<!--                    </span>-->
				<!--                </el-dialog>-->
				<!--                导入数据-->
				<el-dialog
					title="导入数据"
					:visible.sync="updataFlag"
					width="345px"
					center
				>
					<span style="display: block;padding-bottom: 8px"
						>请选择*.json文件格式：</span
					>
					<el-input v-model="filesInfo"></el-input>
					<input
						type="file"
						style="opacity: 0;"
						ref="importFiles"
						class="file"
						accept="application/json"
						@change="dataImport"
					/>
					<span slot="footer" class="dialog-footer">
						<el-button @click="updataFlag = false">取 消</el-button>
						<el-button
							type="primary"
							@click="submit"
							style="background: #70CCFF;border: 1px solid #70CCFF;"
							>确 定</el-button
						>
					</span>
				</el-dialog>
				<!--                itemList-->
				<div
					class="todolist fcc ac"
					data-step="3"
					data-intro="完成的内容鼠标点击拖入分类区域"
				>
					<div
						class="item"
						draggable="true"
						@dragstart="dragstart($event, item)"
						@dragend="dragend"
						v-for="(item, index) in itemsl"
						:key="index"
					>
						<div class="num">{{ item.sum }}</div>
						<div class="txt fcc ac">{{ item.affair_name }}</div>
					</div>
				</div>
				<div class="bot-txt">
					<div class="explain" @click="openexplain">
						<i class="iconfont iconicon-"></i>
						使用说明
					</div>
					<p>
						<span @click="dataExport"
							>导出json数据【本地备份】</span
						>
					</p>
					<p>
						<span @click="updataFlag = true"
							>导入json数据【恢复数据】</span
						>
					</p>
					<p @click="queryOpinion">
						<span>优化建议&意见反馈</span>
					</p>
				</div>
				<div class="sign-out-btn">
					<span @click="logOutFlag = true">退出登录</span>
				</div>
				<!--                退出登录-->
				<el-dialog
					title="是否退出登录？"
					:visible.sync="logOutFlag"
					width="300px"
					center
				>
					<span slot="footer" class="dialog-footer">
						<el-button @click="logOutFlag = false">取 消</el-button>
						<el-button
							type="primary"
							@click="signOut"
							style="background: #70CCFF;border: 1px solid #70CCFF;"
							>确 定</el-button
						>
					</span>
				</el-dialog>
			</div>
			<!--      内容右边-->
			<div class="main-right">
				<div class="t-time">{{ dateTime }}</div>
				<div class="content">
					<el-dialog
						title="编辑事务名称,并确认"
						:visible.sync="editFlog"
						width="345px"
						center
					>
						<el-input
							v-model="newName"
							autocomplete="off"
							@keyup.enter.native="editConfirm"
						></el-input>
						<span slot="footer" class="dialog-footer">
							<el-button @click="editFlog = false"
								>取 消</el-button
							>
							<el-button
								type="primary"
								@click="editConfirm"
								style="background: #70CCFF;border: 1px solid #70CCFF;"
								>确 定</el-button
							>
						</span>
					</el-dialog>
					<el-dialog
						title="信息"
						:visible.sync="delFlag"
						width="300px"
						center
					>
						<span>您确定要删除该条事务吗？</span>
						<span slot="footer" class="dialog-footer">
							<el-button @click="delFlag = false"
								>取 消</el-button
							>
							<el-button
								type="primary"
								@click="delConfirm"
								style="background: #70CCFF;border: 1px solid #70CCFF;"
								>确 定</el-button
							>
						</span>
					</el-dialog>
					<el-dialog
						title="信息"
						:visible.sync="dragDelFlag"
						width="300px"
						center
					>
						<span>您确定要删除该条事务吗？</span>
						<span slot="footer" class="dialog-footer">
							<el-button @click="dragDelFlag = false"
								>取 消</el-button
							>
							<el-button
								type="primary"
								@click="dragDel"
								style="background: #70CCFF;border: 1px solid #70CCFF;"
								>确 定</el-button
							>
						</span>
					</el-dialog>
					<el-dialog
						title="信息"
						:visible.sync="compFlag"
						width="300px"
						center
					>
						<span>您确定完成这个事务了嘛？</span>
						<span slot="footer" class="dialog-footer">
							<el-button @click="compFlag = false"
								>取 消</el-button
							>
							<el-button
								type="primary"
								@click="compConfirm"
								style="background: #70CCFF;border: 1px solid #70CCFF;"
								>确 定</el-button
							>
						</span>
					</el-dialog>
					<el-dialog
						title="信息"
						:visible.sync="redFlag"
						width="300px"
						center
					>
						<span>您确定完要还原这个事务吗？</span>
						<span slot="footer" class="dialog-footer">
							<el-button @click="redFlag = false"
								>取 消</el-button
							>
							<el-button
								type="primary"
								@click="redConfirm"
								style="background: #70CCFF;border: 1px solid #70CCFF;"
								>确 定</el-button
							>
						</span>
					</el-dialog>
					<div
						v-for="(item, index) in dataArr"
						:key="item.id"
						:ref="item.ref"
						:class="item.class"
						@drop="drop"
						@dragover.prevent
					>
						<!--                        可能拖到任何位置 所以给每个地方加上自定义属性-->
						<div class="thread" :ref="item.ref">
							<div class="thread-cbox" :ref="item.ref">
								<div class="text-c" :ref="item.ref">
									<span
										v-for="(conitem, index) in item.content"
										:key="index"
										:ref="item.ref"
										>{{ conitem }}</span
									>
								</div>
								<div class="cen-t" :ref="item.ref">
									{{ item.title }}
								</div>
								<!--                                按钮A区域-->
								<div
									class="btnbox"
									:ref="item.ref"
									v-if="item.ref === 'threadA'"
									data-step="4"
									data-intro="编辑或拖动到其他事务区"
									@click="candit"
								>
									<div
										class="btn-txt"
										v-for="item in itemsClt"
										:key="item.id"
									>
										<div
											class="btn hover_frames"
											v-show="item.status === 1"
											@click.stop="editBtn(item.id)"
											:ref="item.ref"
											draggable="true"
											@dragstart="dragstart($event, item)"
											@dragend="dragend"
											:title="item.affair_name"
										>
											{{ item.affair_name }}
										</div>
										<div
											class="btn completeActive"
											v-show="item.status === 2"
											@click.stop="editBtn(item.id)"
											:ref="item.ref"
											draggable="true"
											@dragstart="dragstart($event, item)"
											@dragend="dragend"
											:title="item.affair_name"
										>
											{{ item.affair_name }}
										</div>
										<!--                                    此处添加按钮事件-->
										<div
											class="btnEdit"
											v-show="
												item.id === editId && editActive
											"
										>
											<ul>
												<li>
													<span
														@click="
															edit(
																item.id,
																item.affair_name
															)
														"
														>编辑</span
													>
												</li>
												<li>
													<span @click="del(item.id)"
														>删除</span
													>
												</li>
												<li>
													<span
														v-show="
															item.status === 2
														"
														>已完成</span
													>
												</li>
												<li>
													<span
														@click="
															complete(item.id)
														"
														v-show="
															item.status === 1
														"
														>完成</span
													>
												</li>
												<li>
													<span
														@click="
															reduction(item.id)
														"
														>还原</span
													>
												</li>
												<li>
													<span>{{
														item.updated_time
													}}</span>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<!--                                按钮B区域-->
								<div
									class="btnbox"
									:ref="item.ref"
									v-if="item.ref === 'threadB'"
									@click="candit"
								>
									<div
										class="btn-txt"
										v-for="item in itemsCrt"
										:key="item.id"
									>
										<div
											class="btn hover_frames"
											v-show="item.status === 1"
											@click.stop="editBtn(item.id)"
											:ref="item.ref"
											draggable="true"
											@dragstart="dragstart($event, item)"
											@dragend="dragend"
											:title="item.affair_name"
										>
											{{ item.affair_name }}
										</div>
										<div
											class="btn completeActive"
											v-show="item.status === 2"
											@click.stop="editBtn(item.id)"
											:ref="item.ref"
											draggable="true"
											@dragstart="dragstart($event, item)"
											@dragend="dragend"
											:title="item.affair_name"
										>
											{{ item.affair_name }}
										</div>
										<!--                                    此处添加按钮事件-->
										<div
											class="btnEdit"
											v-show="
												item.id === editId && editActive
											"
										>
											<ul>
												<li>
													<span
														@click="
															edit(
																item.id,
																item.affair_name
															)
														"
														>编辑</span
													>
												</li>
												<li>
													<span @click="del(item.id)"
														>删除</span
													>
												</li>
												<li>
													<span
														v-show="
															item.status === 2
														"
														>已完成</span
													>
												</li>
												<li>
													<span
														@click="
															complete(item.id)
														"
														v-show="
															item.status === 1
														"
														>完成</span
													>
												</li>
												<li>
													<span
														@click="
															reduction(item.id)
														"
														>还原</span
													>
												</li>
												<li>
													<span>{{
														item.updated_time
													}}</span>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<!--                                按钮C区域-->
								<div
									class="btnbox"
									:ref="item.ref"
									v-if="item.ref === 'threadC'"
									@click="candit"
								>
									<div
										class="btn-txt"
										v-for="item in itemsClb"
										:key="item.id"
									>
										<div
											class="btn hover_frames"
											v-show="item.status === 1"
											@click.stop="editBtn(item.id)"
											:ref="item.ref"
											draggable="true"
											@dragstart="dragstart($event, item)"
											@dragend="dragend"
											:title="item.affair_name"
										>
											{{ item.affair_name }}
										</div>
										<div
											class="btn completeActive"
											v-show="item.status === 2"
											@click.stop="editBtn(item.id)"
											:ref="item.ref"
											draggable="true"
											@dragstart="dragstart($event, item)"
											@dragend="dragend"
											:title="item.affair_name"
										>
											{{ item.affair_name }}
										</div>
										<!--                                    此处添加按钮事件-->
										<div
											class="btnEdit"
											v-show="
												item.id === editId && editActive
											"
										>
											<ul>
												<li>
													<span
														@click="
															edit(
																item.id,
																item.affair_name
															)
														"
														>编辑</span
													>
												</li>
												<li>
													<span @click="del(item.id)"
														>删除</span
													>
												</li>
												<li>
													<span
														v-show="
															item.status === 2
														"
														>已完成</span
													>
												</li>
												<li>
													<span
														@click="
															complete(item.id)
														"
														v-show="
															item.status === 1
														"
														>完成</span
													>
												</li>
												<li>
													<span
														@click="
															reduction(item.id)
														"
														>还原</span
													>
												</li>
												<li>
													<span>{{
														item.updated_time
													}}</span>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<!--                                按钮D区域-->
								<div
									class="btnbox"
									:ref="item.ref"
									v-if="item.ref === 'threadD'"
									@click="candit"
								>
									<div
										class="btn-txt"
										v-for="item in itemsCrb"
										:key="item.id"
									>
										<div
											class="btn hover_frames"
											v-show="item.status === 1"
											@click.stop="editBtn(item.id)"
											:ref="item.ref"
											draggable="true"
											@dragstart="dragstart($event, item)"
											@dragend="dragend"
											:title="item.affair_name"
										>
											{{ item.affair_name }}
										</div>
										<div
											class="btn completeActive"
											v-show="item.status === 2"
											@click.stop="editBtn(item.id)"
											:ref="item.ref"
											draggable="true"
											@dragstart="dragstart($event, item)"
											@dragend="dragend"
											:title="item.affair_name"
										>
											{{ item.affair_name }}
										</div>
										<!--                                    此处添加按钮事件-->
										<div
											class="btnEdit"
											v-show="
												item.id === editId && editActive
											"
										>
											<ul>
												<li>
													<span
														@click="
															edit(
																item.id,
																item.affair_name
															)
														"
														>编辑</span
													>
												</li>
												<li>
													<span @click="del(item.id)"
														>删除</span
													>
												</li>
												<li>
													<span
														v-show="
															item.status === 2
														"
														>已完成</span
													>
												</li>
												<li>
													<span
														@click="
															complete(item.id)
														"
														v-show="
															item.status === 1
														"
														>完成</span
													>
												</li>
												<li>
													<span
														@click="
															reduction(item.id)
														"
														>还原</span
													>
												</li>
												<li>
													<span>{{
														item.updated_time
													}}</span>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>

						<!-- 重要、紧急、不重要 防止多次渲染 -->
						<div class="tips-txt-top" v-if="index === 0">
							<span>{{ item.tips[0] }}</span>
							<i class="iconfont iconshang"></i>
						</div>
						<div class="tips-txt-left" v-if="index === 0">
							<span>{{ item.tips[1] }}</span>
							<i class="iconfont iconzuo"></i>
						</div>
						<span class="right-bot-top" v-if="index === 0">{{
							item.tips[2]
						}}</span>
						<span class="right-bot-bot" v-if="index === 0">{{
							item.tips[3]
						}}</span>
					</div>
				</div>
				<!--        中心区域-->
				<div class="center">
					<div>一</div>
					<div>二</div>
					<div>三</div>
					<div>四</div>
				</div>
				<!--        删除区域-->
				<div
					class="delete"
					data-affect="del"
					@drop="drop"
					@dragover.prevent
					data-step="5"
					data-intro="拖放删除"
				>
					<i class="iconfont icondelete" style="color: #70CCFF;"></i>
				</div>
			</div>
		</div>
		<div class="copyright">
			©2020-2021轻重缓急All Rights Reserved
			<a href="https://beian.miit.gov.cn/" target="_blank"
				>粤ICP备15072947号-6
			</a>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import CommonHeader from '@/components/CommonHeader.vue'
import IntroJs from 'intro.js'
export default {
	name: 'Home',
	components: {
		CommonHeader,
	},
	data() {
		return {
			//4个盒子内容
			dataArr: [
				{
					id: 1,
					class: 'left-top',
					ref: 'threadA',
					title: '第一时间解决',
					tips: ['重要', '紧急', '不紧急', '不重要'],
					content: [
						'很重要-很紧急',
						'*迫切的问题',
						'*紧急情况',
						'*限期完成的工作',
					],
				},
				{
					id: 2,
					class: 'right-top',
					ref: 'threadB',
					title: '要专注解决',
					content: [
						'很重要-不紧急',
						'*人际关系的确立',
						'*增进自己的能力',
						'*准备工作 预防措施',
						'*价值观澄清计划',
					],
				},
				{
					id: 3,
					class: 'left-bot',
					ref: 'threadC',
					title: '授权他人解决',
					content: [
						'很紧急-不重要',
						'*消息、电话、报告、会议',
						'*造成干扰的事',
						'*迫在眉捷的急事',
						'*别人期望的事情',
					],
				},
				{
					id: 4,
					class: 'right-bot',
					ref: 'threadD',
					title: '尽量减少耗时',
					content: [
						'不重要-不紧急',
						'*广告函件',
						'*浪费时间',
						'*忙碌琐碎的事情',
						'*逃避性问题',
					],
				},
			],
			itemList: [], //原添加数组
			itemsl: [], //左边itemlist
			itemsClt: [], //主内容左上
			itemsCrt: [], //主内容右上
			itemsClb: [], //主内容左下
			itemsCrb: [], //主内容右下
			addcontent: false, //添加遮罩层
			logOutFlag: false, //退出遮罩层
			affair_name: '', // 添加事务名称
			newName: '', //更改事务名称
			editId: null, // 显示编辑ID
			editActive: false, // 显示编辑
			dragendId: '', //拖动元素ID
			type: 1, //type默认
			updataFlag: false, //导入文件遮罩层
			filesInfo: '', // 导入文件名
			jsonfile: {}, // 导入的数据文件
			dateTime: '', // 时间
			typeActive: null, //已完成样式添加
			editFlog: false, // 显示编辑名称
			delFlag: false, //显示删除
			compFlag: false, //显示完成
			redFlag: false, //显示还原
			userActive: '', //标记用户是否时首次使用
			dragDelFlag: false, //拖动删除弹出
		}
	},
	created() {
		console.log(process.env.NODE_ENV)
		this.userActive = localStorage.userActive
		console.log(window.location.protocol + '//' + window.location.host)
	},
	mounted() {
		this.getTime()
		this.getList()
		//判断是否时第一次使用
		if (localStorage.userActive == 1) {
			this.guide()
			this.addcontent = true
		}
	},
	methods: {
		oneActive() {
			console.log(1)
			this.addcontent = true
		},
		//时间为单数时添加 0
		formatNumber(n) {
			const str = n.toString()
			return str[1] ? str : `0${str}`
		},
		//获取当前日期
		getTime() {
			const date = new Date()
			const year = date.getFullYear()
			const month = date.getMonth() + 1
			const day = date.getDate()
			this.dateTime = [year, month, day].map(this.formatNumber).join('.')
		},
		//初始内容 添加 -- A B C D区域
		getList() {
			this.$http({
				url: this.api.affairList,
			}).then((res) => {
				console.log(res)
				if (res.code === 0) {
					let listl = []
					let listClt = []
					let listCrt = []
					let listClb = []
					let listCrb = []
					let sum = 0
					for (let key in res.data) {
						const arr = res.data[key]
						arr.forEach((item) => {
							if (item.status === 0 || item.level === 0) {
								item.sum = ++sum
								listl.push(item)
							} else if (item.level === 1) {
								listClt.push(item)
							} else if (item.level === 2) {
								listCrt.push(item)
							} else if (item.level === 3) {
								listClb.push(item)
							} else if (item.level === 4) {
								listCrb.push(item)
							}
						})
					}
					this.itemsl = listl
					this.itemsClt = listClt
					this.itemsCrt = listCrt
					this.itemsClb = listClb
					this.itemsCrb = listCrb
					if (
						this.itemsl.length === 0 &&
						localStorage.userActive == 1
					) {
						this.itemsl.push({
							affair_name: '事务名称',
							sum: 1,
						})
					}
				}
			})
		},
		//导出数据
		dataExport() {
			this.$http({
				url: this.api.dataExport,
			})
				.then((res) => {
					console.log('导出', res)
					if (res.code === 0 && res.data.length > 0) {
						const blob = new Blob([JSON.stringify(res.data)], {
							type: 'text/plain',
						})
						const fileName =
							'affair_data_' + new Date().valueOf() + '.json'
						const link = document.createElement('a')
						link.href = window.URL.createObjectURL(blob)
						link.download = fileName
						link.click()
						window.URL.revokeObjectURL(link.href)
						this.$message.success({
							message: '导出成功',
							center: true,
						})
					}
				})
				.catch((err) => console.log(err))
		},
		//导入数据
		dataImport() {
			let that = this
			let selectedFile = this.$refs.importFiles.files[0] //获取读取的File对象
			console.log(this.$refs.importFiles.value)
			//let name = selectedFile.name;//读取选中文件的文件名
			//let size = selectedFile.size;//读取选中文件的大小
			that.filesInfo = that.$refs.importFiles.value //显示选择文件的路径
			let reader = new FileReader() //这里是核心！！！读取操作就是由它完成的。
			reader.readAsText(selectedFile) //读取文件的内容
			console.log(reader)
			reader.onload = function() {
				// console.log("读取结果：", this.result);//当读取完成之后会回调这个函数，然后此时文件的内容存储到了result中。直接操作即可。
				// console.log("读取结果转为JSON：");
				console.log(this.result)
				// let jsonfile = JSON.parse(this.result);
				// console.log(jsonfile)
				that.jsonfile = this.result
			}
		},
		//导入数据提交
		submit() {
			this.$http({
				url: this.api.dataImport,
				data: {
					data: this.jsonfile,
				},
			})
				.then((res) => {
					console.log(res)
					if (res.code === 0) {
						this.getList()
						this.$message.success({
							message: res.msg,
							center: true,
						})
						this.filesInfo = ''
						this.updataFlag = false
					} else {
						this.$message.warning({
							message: res.msg,
							center: true,
						})
					}
				})
				.catch((err) => console.log(err))
		},
		// 按钮
		candit() {
			this.editActive = false
		},
		editBtn(id) {
			console.log('按钮id', id)
			if (id != this.editId) this.editActive = false
			this.editId = id
			this.editActive = !this.editActive

			// 点击按钮 滚动条滚动到最下面
			let con = document.querySelectorAll('.btnbox') // 获取对象
			con.forEach((item) => {
				setTimeout(() => {
					item.scrollTop = item.scrollHeight // 滚动高度
					item.scrollLeft = item.scrollWidth
				}, 0)
			})
		},
		//编辑更改事务名称
		edit(id, name) {
			this.editId = id
			this.newName = name
			this.editFlog = true
			this.editActive = false
		},
		//编辑确认
		editConfirm() {
			this.$http({
				url: this.api.editName,
				data: {
					affair_name: this.newName,
					id: this.editId,
				},
			})
				.then((res) => {
					console.log(res)
					if (res.code === 0) {
						this.$message.success({
							message: res.msg,
							center: true,
						})
						this.getList()
						this.editFlog = false
					} else {
						this.$message.warning({
							message: res.msg,
							center: true,
						})
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
		//删除事务
		del(id) {
			this.editId = id
			this.delFlag = true
			this.editActive = false
		},
		// 删除确认
		delConfirm() {
			this.$http({
				url: this.api.affairHandle,
				data: {
					type: 1, //删除
					id: this.editId,
				},
			}).then((res) => {
				if (res.code === 0) {
					this.$message.success({
						message: res.msg,
						center: true,
					})
					this.getList()
					this.editActive = false
					this.delFlag = false
				} else {
					this.$message.warning({
						message: res.msg,
						center: true,
					})
				}
			})
		},
		//完成事务
		complete(id) {
			this.editId = id
			this.compFlag = true
			this.editActive = false
		},
		//完成
		compConfirm() {
			this.$http({
				url: this.api.affairHandle,
				data: {
					type: 3, //完成
					id: this.editId,
				},
			})
				.then((res) => {
					console.log(res)
					if (res.code === 0) {
						this.$message.success({
							message: res.msg,
							center: true,
						})
						this.getList()
						this.editActive = false
						this.compFlag = false
					} else {
						this.$message.warning({
							message: res.msg,
							center: true,
						})
					}
				})
				.catch((err) => console.log(err))
		},
		//还原事务
		reduction(id) {
			this.editId = id
			this.redFlag = true
			this.editActive = false
		},
		//还原
		redConfirm() {
			// if (status != 2) {
			//     this.$message.warning({
			//         message: '未完成状态无法还原！',
			//         center: true
			//     })
			//     this.editActive = false
			//     return
			// }
			this.$http({
				url: this.api.affairHandle,
				data: {
					type: 2, //还原事务
					id: this.editId,
				},
			})
				.then((res) => {
					console.log(res)
					if (res.code === 0) {
						this.$message.success({
							message: res.msg,
							center: true,
						})
						this.getList()
						this.editActive = false
						this.redFlag = false
					} else {
						this.$message.warning({
							message: res.msg,
							center: true,
						})
					}
				})
				.catch((err) => console.log(err))
		},
		//拖拽松开
		drop(event) {
			let affect = event.currentTarget.dataset.affect
			let level = null
			// A区域任意位置
			console.log(this.$refs.threadA)
			this.$refs.threadA.forEach((item) => {
				if (event.toElement === item) level = 1
			})
			// B区域任意位置
			this.$refs.threadB.forEach((item) => {
				if (event.toElement === item) level = 2
			})
			// C区域任意位置
			this.$refs.threadC.forEach((item) => {
				if (event.toElement === item) level = 3
			})
			// D区域任意位置
			this.$refs.threadD.forEach((item) => {
				if (event.toElement === item) level = 4
			})
			console.log('level', level)
			console.log('目标存放在', event.toElement)
			// 拖到A区域
			if (level === 1) {
				// let btnbox = document.querySelectorAll('.btnbox')[0]
				// console.log()
				// window.scrollTo(0, btnbox.scrollHeight)
				// 限制添加数量
				// if (this.itemsClt.length >= 40) return this.$message.warning({
				//     message: '存放上限！',
				//     center: true
				// })
				this.$http({
					url: this.api.edit_status_level,
					data: {
						level,
						type: this.type,
						id: this.dragendId,
					},
				}).then((res) => {
					console.log(res)
					if (res.code === 0) {
						this.$message.success({
							message: res.msg,
							center: true,
						})
						this.getList()
					} else if (res.code === -1)
						this.$message.warning({
							message: res.msg,
							center: true,
						})
				})
			}
			// 拖到B区域
			if (level === 2) {
				// if (this.itemsCrt.length >= 20) return this.$message.warning({
				//     message: '存放上限！',
				//     center: true
				// })
				this.$http({
					url: this.api.edit_status_level,
					data: {
						level,
						type: this.type,
						id: this.dragendId,
					},
				}).then((res) => {
					console.log(res)
					if (res.code === 0) {
						this.$message.success({
							message: res.msg,
							center: true,
						})
						this.getList()
					} else if (res.code === -1)
						this.$message.warning({
							message: res.msg,
							center: true,
						})
				})
			}
			// 拖到C区域
			if (level === 3) {
				// if (this.itemsClb.length >= 20) return this.$message.warning({
				//     message: '存放上限！',
				//     center: true
				// })
				this.$http({
					url: this.api.edit_status_level,
					data: {
						level,
						type: this.type,
						id: this.dragendId,
					},
				}).then((res) => {
					console.log(res)
					if (res.code === 0) {
						this.$message.success({
							message: res.msg,
							center: true,
						})
						this.getList()
					} else if (res.code === -1)
						this.$message.warning({
							message: res.msg,
							center: true,
						})
				})
			}
			// 拖到D区域
			if (level === 4) {
				// if (this.itemsCrb.length >= 20) return this.$message.warning({
				//     message: '存放上限！',
				//     center: true
				// })
				this.$http({
					url: this.api.edit_status_level,
					data: {
						level,
						type: this.type,
						id: this.dragendId,
					},
				}).then((res) => {
					console.log(res)
					if (res.code === 0) {
						this.$message.success({
							message: res.msg,
							center: true,
						})
						this.getList()
					} else if (res.code === -1)
						this.$message.warning({
							message: res.msg,
							center: true,
						})
				})
			}
			// 拖到删除区域
			if (affect === 'del') {
				this.dragDelFlag = true
				// console.log(event)
				// let data = qs.stringify({
				//     type: 1, //删除
				//     id: this.dragendId
				// })
				// this.$http({
				//     url: this.api.affairHandle,
				//     method: 'post',
				//     data
				// }).then(res => {
				//     if (res.code === 0) {
				//         this.$message.success({
				//             message: res.msg,
				//             center: true
				//         })
				//         this.getList()
				//     } else if (res.code === 1) this.$message.warning({
				//         message: res.msg,
				//         center: true
				//     })
				// })
			}
		},
		// 删除确认
		dragDel() {
			this.$http({
				url: this.api.affairHandle,
				data: {
					type: 1, //删除
					id: this.dragendId,
				},
			}).then((res) => {
				if (res.code === 0) {
					this.$message.success({
						message: res.msg,
						center: true,
					})
					this.getList()
					this.editActive = false
					this.dragDelFlag = false
				} else if (res.code === 1)
					this.$message.warning({
						message: res.msg,
						center: true,
					})
			})
		},
		// 拖拽开始触发的函数，可在此获取元素
		dragstart(event, item) {
			console.log(item)
			this.editActive = false
			this.dragendId = item.id
			this.type = item.status === 0 || item.level === 0 ? 1 : 2
			event.dataTransfer.setData('item', item.affair_name)
		},
		// 拖拽结束
		dragend(event) {
			event.dataTransfer.clearData()
		},
		// 添加事务
		confirmAdd() {
			this.addcontent = false
			console.log(this.affair_name)
			this.$http({
				url: this.api.affairAdd,
				data: {
					affair_name: this.affair_name,
				},
			}).then((res) => {
				console.log(res)
				if (res.code === 0) {
					this.$message.success({
						message: res.msg + res.data.updated_time,
						center: true,
					})
					this.getList()
					this.affair_name = ''
				} else if (res.code === 1) {
					this.$message.info({
						message: res.msg,
						center: true,
					})
				}
			})
		},
		// 退出登录
		signOut() {
			// const username = localStorage.j_username ? localStorage.j_sername : ''
			// const token = localStorage.j_token ? localStorage.j_token : ''
			this.$http({
				url: this.api.logout,
			})
				.then((res) => {
					console.log(res)
					if (res.code === 0) {
						this.$message.success({
							message: res[0],
							center: true,
						})
						localStorage.removeItem('j_token')
						localStorage.removeItem('j_username')
						this.$router.push('/login')
					} else {
						this.$message.warning({
							message: res.msg,
							center: true,
						})
					}
				})
				.catch((err) => console.log(err))
		},
		//跳转使用说明
		openexplain() {
			this.$router.push('/explain')
		},
		//跳转意见反馈
		queryOpinion() {
			this.$router.push('/opinion')
		},
		guide() {
			IntroJs()
				.setOptions({
					prevLabel: '上一步',
					nextLabel: '下一步',
					skipLabel: '跳过',
					doneLabel: '结束',
					/* 是否允许点击空白处退出 */
					exitOnOverlayClick: false,
					/* 引导说明文本框的样式 */
					tooltipClass: '',
					/* 说明高亮区域的样式 */
					highlightClass: '',
					/* 遮罩层的透明度 */
					overlayOpacity: 0.5,
					// 是否在红色圆圈中显示步骤编号
					showStepNumbers: false,
					// 是否显示介绍进度
					showProgress: false,
					// 在第一步中隐藏上一个按钮？否则，将禁用按钮。
					hidePrev: true,
					// 在第一步中隐藏上一个按钮？否则，将禁用按钮。
					hideNext: true,
				})
				.oncomplete(() => {
					//点击跳过按钮后执行的事件
				})
				.onexit(() => {
					//点击结束按钮后， 执行的事件
					console.log('我点了结束')
					localStorage.userActive = 0
					this.addcontent = false
					this.getList()
				})
				.start()
		},
	},
}
</script>
<style lang="less" scoped>
#home {
	background: #f9f9f9;
	height: 100vh;
	/*修改elementui样式*/
	.zzbox {
		width: 100%;
		height: 100%;
		/*background: rgba(0,0,0,.5);*/
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;

		.zzcont_box {
			margin: 300px auto;
			width: 300px;
			background: white;
			position: relative;
			padding: 30px 30px 30px 30px;
			box-shadow: 0px 3px 7px 0px rgba(223, 223, 223, 0.8);
			#x {
				position: absolute;
				right: 10px;
				top: 0;
				line-height: 50px;
				z-index: 3;
				font-size: 24px;
				color: #5d5d5d;
				cursor: pointer;
			}
			.zz_head {
				position: absolute;
				top: 0;
				left: 0;
				padding-left: 30px;
				color: #70ccff;
				width: 100%;
				height: 50px;
				background: #f8f8f8;
				line-height: 50px;
				box-sizing: border-box;
				border-bottom: 1px solid #ccc;
			}
			.zzcent {
				margin: 40px 0 20px 0;
				input {
					width: 300px;
					height: 48px;
					padding-left: 10px;
					background: white;
					border: 1px solid #d6d6d6;
					box-sizing: border-box;
					line-height: 50px;
					font-size: 16px;
				}
			}
			.zzbot {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 10px;
				a:first-of-type {
					width: 140px;
					height: 38px;
					border: 1px solid #d6d6d6;
					text-align: center;
					line-height: 38px;
					cursor: pointer;
					border-radius: 4px;
					font-size: 18px;
					font-family: Source Han Sans SC Regular,
						Source Han Sans SC Regular-Regular;
					font-weight: 400;
					color: #d3d3d3;
					margin-right: 20px;
					box-sizing: border-box;
				}
				a:last-of-type {
					width: 140px;
					height: 38px;
					background: #70ccff;
					text-align: center;
					line-height: 38px;
					cursor: pointer;
					font-size: 18px;
					font-family: Source Han Sans SC Regular,
						Source Han Sans SC Regular-Regular;
					font-weight: 400;
					color: #ffffff;
					border-radius: 4px;
					box-sizing: border-box;
				}
			}
		}
	}
	.el-dialog__wrapper {
		min-width: 1200px;
	}
	.wrap {
		width: 1200px;
		height: 814px;
		background: white;
		margin: 12px auto 0 auto;
		/*左边样式*/
		.main-left {
			width: 214px;
			height: 814px;
			float: left;
			border: 1px solid #70ccff;
			box-sizing: border-box;
			position: relative;
			.file {
				width: 295px;
				height: 40px;
				position: absolute;
				left: 25px;
				cursor: pointer;
			}
			.add-content {
				width: 165px;
				height: 30px;
				background: #70ccff;
				text-align: center;
				line-height: 30px;
				color: #fffefe;
				font-size: 14px;
				cursor: text;
				margin: 20px auto 0 auto;
			}

			.add-goods {
				width: 165px;
				height: 30px;
				border: 1px solid #70ccff;
				box-sizing: border-box;
				margin: 20px auto 20px auto;
				font-size: 14px;
				line-height: 30px;
				text-align: center;
				cursor: pointer;
				background: #70ccff;
				color: #fffefe;
				.iconziyuan {
					font-size: 14px;
				}
			}

			/*新增事务*/

			.ac {
				align-items: center;
			}

			.fcc {
				display: flex;
				flex-direction: column;
			}

			.todolist {
				margin: auto;
				width: 165px;
				max-height: 500px;
				overflow-y: auto;
				box-sizing: border-box;
				padding: 10px 0 10px 0;
				.item {
					display: grid;
					grid-template-columns: 1fr 4fr;
					width: 148px;
					height: 50px;

					.topNone {
						border-top: none;
					}
				}
				.item:last-of-type > .num {
					border-bottom: 1px solid #70ccff;
				}
				.item:last-of-type > .txt {
					border-bottom: 1px solid #70ccff;
				}
				.num {
					border-right: none;
					line-height: 50px;
					text-align: center;
				}

				.txt {
					padding: 5px;
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					overflow: hidden;
					white-space: normal;
					word-break: break-all;
				}

				.num,
				.txt {
					font-size: 14px;
					// border: 1px solid #70CCFF;
					border-top: 1px solid #70ccff;
					border-left: 1px solid #70ccff;
					border-right: 1px solid #70ccff;
					color: rgba(16, 16, 16, 1);
				}

				.num {
					border-right: none;
				}
			}
			/*修改滚动条样式*/
			.todolist::-webkit-scrollbar {
				width: 8px;
				height: 8px;
			}
			.todolist::-webkit-scrollbar-track {
				background: rgb(239, 239, 239);
				border-radius: 10px;
			}
			.todolist::-webkit-scrollbar-thumb {
				background: #a6deff;
				border-radius: 10px;
			}
			.todolist::-webkit-scrollbar-thumb:hover {
				background: #70ccff;
			}
			.todolist::-webkit-scrollbar-corner {
				background: rgba(239, 239, 239, 0);
			}
			/*.todolist::-webkit-scrollbar {*/
			/*    display: none;*/
			/*}*/
			.bot-txt {
				width: 212px;
				position: absolute;
				bottom: 40px;
				text-align: center;
				font-size: 15px;
				font-family: Source Han Sans SC Regular,
					Source Han Sans SC Regular-Regular;
				font-weight: 400;
				color: #666666;

				.explain {
					cursor: pointer;
				}

				p {
					font-size: 12px;
					font-family: Source Han Sans SC Regular,
						Source Han Sans SC Regular-Regular;
					font-weight: 400;
					color: #666666;
					line-height: 22px;

					span {
						cursor: pointer;
					}
				}
			}

			.sign-out-btn {
				width: 212px;
				height: 34px;
				border-top: 1px solid #70ccff;
				position: absolute;
				bottom: 0;
				text-align: center;
				line-height: 34px;

				span {
					font-size: 15px;
					font-family: Microsoft YaHei Regular,
						Microsoft YaHei Regular-Regular;
					font-weight: 400;
					color: #333333;
					cursor: pointer;
				}
			}
		}
		/*右边样式*/
		.main-right {
			width: calc(1200px - 214px);
			height: 814px;
			float: right;
			border: 1px solid #70ccff;
			border-bottom: none;
			box-sizing: border-box;
			border-left: none;
			position: relative;

			.t-time {
				width: calc(1200px - 214px);
				height: 40px;
				text-align: center;
				background: #70ccff;
				font-size: 24px;
				font-family: Adobe Heiti Std R, Adobe Heiti Std R-R;
				color: #ffffff;
				line-height: 40px;
			}

			.content {
				width: calc(1200px - 214px - 1px);
				height: calc(814px - 40px - 35px - 2px);
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				position: relative;

				.left-top,
				.right-top,
				.left-bot,
				.right-bot {
					width: calc((1200px - 214px - 1px) / 2);
				}
				.left-top,
				.right-top {
					box-sizing: border-box;
					border-right: 1px solid #70ccff;
					border-bottom: 1px solid #70ccff;

					.thread {
						width: calc((1200px - 214px - 1px) / 2);
						height: calc((814px - 40px - 36px) / 2);
						box-sizing: border-box;
						position: absolute;

						.thread-cbox {
							width: calc((1200px - 214px - 1px) / 2);
							height: calc((814px - 40px - 36px) / 2);
							float: left;
							position: relative;
							box-sizing: border-box;
							padding: 12px 12px 0 12px;

							.text-c span {
								display: block;
								font-size: 12px;
								font-family: Source Han Sans SC Regular,
									Source Han Sans SC Regular-Regular;
								font-weight: bold;
								text-align: left;
								color: #eb2929;
								line-height: 20px;
							}

							.text-c span:first-of-type {
								font-size: 14px;
								font-family: Source Han Sans SC Regular,
									Source Han Sans SC Regular-Regular;
								font-weight: bold;
								text-align: left;
								color: #eb2929;
							}

							.cen-t {
								font-weight: bold;
								color: #eb2929;
								font-size: 16px;
								text-align: center;
								position: absolute;
								top: 60px;
								left: 220px;
								right: 0;
								margin: auto;
							}
							.btnbox {
								height: 231px;
								max-height: 231px;
								overflow-y: scroll;
								padding: 16px 16px 0 0px;
								box-sizing: border-box;
								display: flex;
								flex-wrap: wrap;
								align-content: flex-start;
								margin-top: 10px;

								.btn-txt {
									position: relative;
									.btn {
										/*width: 60px;*/
										// max-width: 160px;

										background: #70ccff;
										font-size: 14px;
										color: white;
										border-radius: 2px;
										cursor: pointer;
										margin: 4px;
										// overflow-x: hidden;
										// text-overflow: ellipsis;
										// white-space: nowrap;
										padding: 5px 10px;
										/*transition:all 1s;*/
									}

									.hover_frames:hover {
										cursor: pointer;
										background: #a6e0ff;
									}
									.completeActive {
										text-decoration: line-through;
										background: #d6cece;
									}
									.btnEdit {
										position: absolute;
										top: 34px;
										width: 92px;
										height: auto;
										box-sizing: border-box;
										padding: 5px;
										font-size: 14px;
										background: rgba(255, 255, 255, 1);
										border: 1px solid #70ccff;
										z-index: 999999;
										li {
											line-height: 30px;
											span {
												cursor: pointer;
											}
										}
										li:last-of-type {
											border-top: 1px solid #70ccff;
											span {
												cursor: text;
											}
										}
									}
								}
							}
							/*修改滚动条样式*/
							.btnbox::-webkit-scrollbar {
								width: 8px;
								height: 8px;
							}
							.btnbox::-webkit-scrollbar-track {
								background: rgb(239, 239, 239);
								border-radius: 10px;
							}
							.btnbox::-webkit-scrollbar-thumb {
								background: #a6deff;
								border-radius: 10px;
							}
							.btnbox::-webkit-scrollbar-thumb:hover {
								background: #70ccff;
							}
							.btnbox::-webkit-scrollbar-corner {
								background: rgba(239, 239, 239, 0);
							}
							/*.btnbox::-webkit-scrollbar{*/
							/*    display: none !important;*/
							/*}*/
						}
					}

					.tips-txt-top {
						width: 44px;
						height: 44px;
						background: #ffffff;
						color: #ed522e;
						font-size: 15px;
						font-family: Microsoft YaHei Regular,
							Microsoft YaHei Regular-Regular;
						font-weight: 400;
						text-align: center;
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						margin: auto;

						span {
							display: block;
							padding-top: 12px;
						}

						.iconshang {
							/*font-size: 24px;*/
							color: #70ccff;
						}
					}

					.tips-txt-left {
						width: 44px;
						height: 44px;
						background: #ffffff;
						color: #eb2929;
						font-size: 15px;
						font-family: Microsoft YaHei Regular,
							Microsoft YaHei Regular-Regular;
						font-weight: 400;
						text-align: center;
						position: absolute;
						left: 0;
						bottom: calc((814px - 40px - 35px - 2px) / 2 - 22px);
						margin: auto;
						writing-mode: vertical-lr;

						span {
							display: block;
							padding-left: 12px;
						}

						.iconzuo {
							/*font-size: 24px;*/
							color: #70ccff;
						}
					}
					.right-bot-top {
						display: inline-block;
						position: absolute;
						top: calc((814px - 40px - 36px) / 2 - 45px / 2);
						right: 0;
						padding: 0 12px;
						background: #fff;
						color: #aaaaaa;
						writing-mode: vertical-lr;
					}

					.right-bot-bot {
						display: inline-block;
						position: absolute;
						left: calc((1200px - 214px - 1px) / 2 - 48px / 2);
						bottom: 0;
						padding: 12px 0;
						background: #fff;
						color: #aaaaaa;
					}
				}
				.right-top {
					border-right: none;
					.thread-cbox {
						padding: 12px 12px 0 0 !important;
						.text-c {
							position: absolute;
							right: 12px;
							span {
								color: #ffa21d !important;
							}
							span:nth-of-type(4) {
								position: relative;
								right: 116px;
								bottom: 40px;
							}
							span:nth-of-type(5) {
								position: relative;
								right: 116px;
								bottom: 40px;
							}
						}
						.cen-t {
							color: #ffa21d !important;
							/*bottom: 46px !important;*/
							left: 0px !important;
							right: 212px !important;
							margin: auto !important;
						}
						.btnbox {
							margin-top: 90px !important;
							padding: 16px 16px 0 12px !important;
						}
					}
				}
				.left-bot {
					box-sizing: border-box;
					border-right: 1px solid #70ccff;

					.thread {
						width: calc((1200px - 214px - 1px) / 2);
						height: calc((814px - 40px - 36px) / 2);
						box-sizing: border-box;
						position: absolute;
						left: 0;

						.thread-cbox {
							width: calc((1200px - 214px - 1px) / 2);
							height: calc((814px - 40px - 36px) / 2);
							float: left;
							position: relative;
							box-sizing: border-box;
							padding: 0 12px 12px 12px;

							.text-c {
								position: absolute;
								bottom: -30px;
							}

							.text-c span {
								display: block;
								font-size: 12px;
								font-family: Source Han Sans SC Regular,
									Source Han Sans SC Regular-Regular;
								font-weight: bold;
								text-align: left;
								color: #ffa21d;
								line-height: 20px;
							}

							.text-c span:first-of-type {
								font-size: 14px;
								font-family: Source Han Sans SC Regular,
									Source Han Sans SC Regular-Regular;
								font-weight: bold;
								text-align: left;
								color: #ffa21d;
							}
							span:nth-of-type(4) {
								position: relative;
								bottom: 40px;
								left: 160px;
							}
							span:nth-of-type(5) {
								position: relative;
								bottom: 40px;
								left: 160px;
							}
							.cen-t {
								font-weight: bold;
								color: #ffa21d;
								font-size: 16px;
								text-align: center;
								position: absolute;
								bottom: 44px;
								left: 220px;
								right: 0;
								margin: auto;
							}
							.btnbox {
								height: 231px;
								max-height: 231px;
								overflow-y: scroll;
								padding: 16px 16px 0 0;
								box-sizing: border-box;
								display: flex;
								flex-wrap: wrap;
								align-content: flex-start;
								margin-top: 32px;
								.btn-txt {
									position: relative;
									.btn {
										/*width: 60px;*/
										// max-width: 160px;
										background: #70ccff;
										font-size: 14px;
										color: white;
										border-radius: 2px;
										cursor: pointer;
										margin: 4px;
										padding: 5px 10px;
									}
									.hover_frames:hover {
										cursor: pointer;
										background: #a6e0ff;
									}
									.completeActive {
										text-decoration: line-through;
										background: #d6cece;
									}
									.btnEdit {
										position: absolute;
										top: 34px;
										width: 92px;
										height: auto;
										box-sizing: border-box;
										padding: 5px;
										font-size: 14px;
										background: rgba(255, 255, 255, 1);
										border: 1px solid #70ccff;
										z-index: 999999;
										li {
											line-height: 30px;
											span {
												cursor: pointer;
											}
										}
										li:last-of-type {
											border-top: 1px solid #70ccff;
											span {
												cursor: text;
											}
										}
									}
								}
							}
							/*修改滚动条样式*/
							.btnbox::-webkit-scrollbar {
								width: 8px;
								height: 8px;
							}
							.btnbox::-webkit-scrollbar-track {
								background: rgb(239, 239, 239);
								border-radius: 10px;
							}
							.btnbox::-webkit-scrollbar-thumb {
								background: #a6deff;
								border-radius: 10px;
							}
							.btnbox::-webkit-scrollbar-thumb:hover {
								background: #70ccff;
							}
							.btnbox::-webkit-scrollbar-corner {
								background: rgba(239, 239, 239, 0);
							}
							/*.btnbox::-webkit-scrollbar{*/
							/*    display: none !important;*/
							/*}*/
						}
					}
				}

				.right-bot {
					position: relative;

					.thread {
						width: calc((1200px - 214px - 1px) / 2);
						height: calc((814px - 40px - 36px) / 2);
						box-sizing: border-box;
						position: absolute;
						left: 0;

						.thread-cbox {
							width: calc((1200px - 214px - 1px) / 2);
							height: calc((814px - 40px - 36px) / 2);
							float: left;
							position: relative;
							box-sizing: border-box;
							padding: 0 12px 12px 0;

							.text-c {
								position: absolute;
								right: 12px;
								bottom: -30px;
								color: #d3d3d3;
							}

							.text-c span {
								display: block;
								font-size: 12px;
								font-family: Source Han Sans SC Regular,
									Source Han Sans SC Regular-Regular;
								font-weight: bold;
								text-align: left;
								color: #d3d3d3;
								line-height: 20px;
							}

							.text-c span:first-of-type {
								font-size: 14px;
								font-family: Source Han Sans SC Regular,
									Source Han Sans SC Regular-Regular;
								font-weight: bold;
								text-align: left;
								color: #d3d3d3;
							}
							span:nth-of-type(4) {
								position: relative;
								right: 110px;
								bottom: 40px;
							}
							span:nth-of-type(5) {
								position: relative;
								right: 110px;
								bottom: 40px;
							}
							.cen-t {
								font-weight: bold;
								color: #aaaaaa;
								font-size: 16px;
								text-align: center;
								position: absolute;
								bottom: 46px;
								left: 0px;
								right: 212px;
								margin: auto;
							}

							.btnbox {
								height: 231px;
								max-height: 231px;
								overflow-y: scroll;
								padding: 16px 16px 0 12px !important;
								box-sizing: border-box;
								display: flex;
								flex-wrap: wrap;
								align-content: flex-start;
								margin-top: 32px;
								.btn-txt {
									position: relative;
									.btn {
										/*width: 60px;*/
										// max-width: 160px;
										background: #70ccff;
										font-size: 14px;
										color: white;
										border-radius: 2px;
										cursor: pointer;
										margin: 4px;
										padding: 5px 10px;
									}
									.hover_frames:hover {
										cursor: pointer;
										background: #a6e0ff;
									}
									.completeActive {
										text-decoration: line-through;
										background: #d6cece;
									}
									.btnEdit {
										position: absolute;
										top: 34px;
										width: 92px;
										height: auto;
										box-sizing: border-box;
										padding: 5px;
										font-size: 14px;
										background: rgba(255, 255, 255, 1);
										border: 1px solid #70ccff;
										z-index: 999999;
										li {
											line-height: 30px;
											span {
												cursor: pointer;
											}
										}
										li:last-of-type {
											border-top: 1px solid #70ccff;
											span {
												cursor: text;
											}
										}
									}
								}
							}
							/*修改滚动条样式*/
							.btnbox::-webkit-scrollbar {
								width: 8px;
								height: 8px;
							}
							.btnbox::-webkit-scrollbar-track {
								background: rgb(239, 239, 239);
								border-radius: 10px;
							}
							.btnbox::-webkit-scrollbar-thumb {
								background: #a6deff;
								border-radius: 10px;
							}
							.btnbox::-webkit-scrollbar-thumb:hover {
								background: #70ccff;
							}
							.btnbox::-webkit-scrollbar-corner {
								background: rgba(239, 239, 239, 0);
							}
							/*.btnbox::-webkit-scrollbar{*/
							/*    display: none !important;*/
							/*}*/
						}
					}
				}
			}

			/*中心区域*/

			.center {
				width: 70px;
				height: 46px;
				border: 1px solid #aaaaaa;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				color: #aaaaaa;

				> div {
					width: calc(60px / 2);
					height: calc(46px / 2);
					text-align: center;
					line-height: calc(46px / 2);
				}
			}

			/*删除样式*/

			.delete {
				width: calc(1200px - 214px - 1px);
				height: 34px;
				text-align: center;
				line-height: 34px;
				border: 1px solid #70ccff;
				position: relative;
				right: 1px;
			}
		}
	}
}
.copyright {
    margin-top: 320px;
	box-sizing: border-box;
	text-align: center;
	padding: 30px 0;
	font-size: 14px;
}
</style>
